import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'

const solutions = [
  {
    name: 'Como funciona',
    href: '/como-funciona',
  },
  {
    name: 'Blog',
    href: 'https://blog.meular.app/',
  },
  {
    name: 'Quem somos',
    href: '/quem-somos',
  },
  {
    name: 'Entrar',
    href: '/entrar',
  },
]

export const MobileMenu = () => {
  return (
    <Menu as="div" className="relative ml-auto desktop:hidden">
      {({ open }) => (
        <>
          <MenuButton
            className={`group inline-flex items-center rounded-full px-2 py-2 text-base font-medium hover:border-strong-yellow focus:outline-none focus-visible:border-strong-yellow`}
          >
            <Bars3Icon
              className={`h-5 w-5 text-black transition duration-150 ease-in-out`}
              aria-hidden="true"
            />
          </MenuButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <MenuItems className="absolute right-0 top-10 z-10 w-[200px] p-4">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                <div className="flex grid flex-col gap-8 bg-white p-4">
                  {solutions.map((item) => (
                    <MenuItem key={item.name}>
                      <a
                        href={item.href}
                        className="flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                      >
                        <p className="text-sm font-medium text-gray-900">
                          {item.name}
                        </p>
                      </a>
                    </MenuItem>
                  ))}
                </div>
              </div>
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  )
}
